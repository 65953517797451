import Vue from 'vue'
import App from '@/App'
import router from '@/router'                 // api: https://github.com/vuejs/vue-router
import store from '@/store'                   // api: https://github.com/vuejs/vuex
import VueCookie from 'vue-cookie'            // api: https://github.com/alfhen/vue-cookie
import '@/element-ui'                         // api: https://github.com/ElemeFE/element
import '@/icons'                              // api: http://www.iconfont.cn/
import '@/element-ui-theme'
import '@/assets/scss/index.scss'
import httpRequest from '@/utils/httpRequest' // api: https://github.com/axios/axios
import {isAuth} from '@/utils'
import cloneDeep from 'lodash/cloneDeep'
import BaiduMap from 'vue-baidu-map'
import {VueJsonp} from 'vue-jsonp'

Vue.use(VueJsonp)

Vue.use(BaiduMap, {
    ak: 'miP4RMG1yZoROVRSqTPghvUaRb68iGUB'
})

Vue.use(VueCookie)
Vue.config.productionTip = false

window.cb = function (ret) {
    console.log('获取回调···', ret)
    var coords = ret.result.routes[0].polyline, pl = [];
    //坐标解压（返回的点串坐标，通过前向差分进行压缩）
    var kr = 1000000;
    for (var i = 2; i < coords.length; i++) {
        coords[i] = Number(coords[i - 2]) + Number(coords[i]) / kr;
    }
    //将解压后的坐标放入点串数组pl中
    for (var i = 0; i < coords.length; i += 2) {
        pl.push(new TMap.LatLng(coords[i], coords[i + 1]));
    }

    display_polyline(pl)//显示路线

    //标记起终点marker
    var marker = new TMap.MultiMarker({
        id: 'marker-layer',
        map: map,
        styles: {
            "start": new TMap.MarkerStyle({
                "width": 25,
                "height": 35,
                "anchor": {x: 16, y: 32},
                "src": 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/start.png'
            }),
            "end": new TMap.MarkerStyle({
                "width": 25,
                "height": 35,
                "anchor": {x: 16, y: 32},
                "src": 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/end.png'
            })
        },
        geometries: [{
            "id": 'start',
            "styleId": 'start',
            "position": new TMap.LatLng(39.984039, 116.307630307503)
        }, {
            "id": 'end',
            "styleId": 'end',
            "position": new TMap.LatLng(39.977263, 116.337063)
        }]
    });
}
window.display_polyline = function (pl) {

    //创建 MultiPolyline显示折线
    var polylineLayer = new TMap.MultiPolyline({
        id: 'polyline-layer', //图层唯一标识
        map: map,//绘制到目标地图
        //折线样式定义
        styles: {
            'style_blue': new TMap.PolylineStyle({
                'color': '#3777FF', //线填充色
                'width': 8, //折线宽度
                'borderWidth': 5, //边线宽度
                'borderColor': '#FFF', //边线颜色
                'lineCap': 'round', //线端头方式
            })
        },
        //折线数据定义
        geometries: [
            {
                'id': 'pl_1',//折线唯一标识，删除时使用
                'styleId': 'style_blue',//绑定样式名
                'paths': pl
            }
        ]
    });
}


// 非生产环境, 适配mockjs模拟数据                 // api: https://github.com/nuysoft/Mock
if (process.env.NODE_ENV !== 'production') {
    // require('@/mock')
}

// 挂载全局
Vue.prototype.$http = httpRequest // ajax请求方法
Vue.prototype.isAuth = isAuth     // 权限方法

// 保存整站vuex本地储存初始状态
window.SITE_CONFIG={}
window.SITE_CONFIG['storeState'] = cloneDeep(store.state)

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
})
