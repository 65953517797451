/**
 * 全站路由配置
 *
 * 建议:
 * 1. 代码中路由统一使用name属性跳转(不使用path属性)
 */
import Vue from 'vue'
import Router from 'vue-router'
import http from '@/utils/httpRequest'
import {isURL} from '@/utils/validate'
import {clearLoginInfo} from '@/utils'

Vue.use(Router)

// 开发环境不使用懒加载, 因为懒加载页面太多的话会造成webpack热更新太慢, 所以只有生产环境使用懒加载
const _import = require('./import-' + process.env.NODE_ENV)

// 全局路由(无需嵌套上左右整体布局)
const globalRoutes = [
	{path: '/404', component: _import('common/404'), name: '404', meta: {title: '404未找到'}},
	{path: '/login', component: _import('common/login'), name: 'login', meta: {title: '登录'}}
]

// 主入口路由(需嵌套上左右整体布局)
const mainRoutes = {
	path: '/',
	component: _import('main'),
	name: 'main',
	redirect: {name: 'home'},
	meta: {title: '主入口整体布局'},
	children: [
		// 通过meta对象设置路由展示方式
		// 1. isTab: 是否通过tab展示内容, true: 是, false: 否
		// 2. iframeUrl: 是否通过iframe嵌套展示内容, '以http[s]://开头': 是, '': 否
		// 提示: 如需要通过iframe嵌套展示内容, 但不通过tab打开, 请自行创建组件使用iframe处理!
		{path: '/home', component: _import('common/home'), name: 'home', meta: {title: '首页'}},
		{path: '/userList', component: _import('user/userList'), name: 'userList', meta: {title: '用户列表', isTab: true}},
		{path: '/allocationList',component: _import('allocation/allocationList'),name: 'allocationList',meta: {title: '配置列表', isTab: true}},
		// {path: '/fuwufeiList',component: _import('allocation/fuwufeiList'),name: 'fuwufeiList',meta: {title: '服务费配置', isTab: true}},
		{path: '/financeList', component: _import('finance/financeList'), name: 'financeList', meta: {title: '财务中心', isTab: true}},
		{path: '/message', component: _import('message/message'), name: 'message', meta: {title: '消息中心', isTab: true}},
		{path: '/taskConfig', component: _import('taskConfig/taskConfig'), name: 'taskConfig', meta: {title: '任务配置', isTab: true}},
		{path: '/bannerList', component: _import('banner/bannerList'), name: 'bannerList', meta: {title: '首页装修', isTab: true}},
		{path: '/mission', component: _import('mission/mission'), name: 'mission', meta: {title: '订单中心', isTab: true}},
		{path: '/system', component: _import('mission/system'), name: 'system', meta: {title: '系统任务', isTab: true}},
		{path: '/missionsye', component: _import('sysmission/missionsye'), name: 'missionsye', meta: {title: '活动派送', isTab: true}},
		{path: '/materialsList', component: _import('materials/materialsList'), name: 'materialsList', meta: {title: '好物圈', isTab: true}},
		{path: '/missionAdd', component: _import('sysmission/missionAdd'), name: 'missionAdd', meta: {title: '发布任务', isTab: false}},
		{path: '/missionRedact',component: _import('sysmission/missionRedact'),name: 'missionRedact',meta: {title: '修改任务', isTab: false}},
		{path: '/userDetail', component: _import('user/userDetail'), name: 'userDetail', meta: {title: '用户详情', isTab: false}},
		{path: '/userDetail1', component: _import('user/userDetail1'), name: 'userDetail1', meta: {title: '用户详情', isTab: false}},
		{path: '/missionDetails',component: _import('mission/missionDetails'),name: 'missionDetails',meta: {title: '任务详情', isTab: false}},
		{path: '/fitmentList', component: _import('fitment/fitmentList'), name: 'fitmentList', meta: {title: '升级配置', isTab: false}},
		{path: '/integral', component: _import('integral/integral'), name: 'integral', meta: {title: '积分推送', isTab: true}},
		{path: '/integralDetail',component: _import('integral/integralDetail'),name: 'integralDetail',meta: {title: '活动详情', isTab: false}},
		{path: '/campus', component: _import('campus/campus'), name: 'campus', meta: {title: '片区列表', isTab: true}},
		{path: '/missionComplain',component: _import('mission/missionComplain'),name: 'missionComplain',meta: {title: '任务投诉', isTab: true}},
		{path: '/autonym', component: _import('autonym/autonym'), name: 'autonym', meta: {title: '司机认证', isTab: true}},
		{path: '/locality', component: _import('locality/locality'), name: 'locality', meta: {title: '本地圈', isTab: true}},
		{path: '/materialLink', component: _import('locality/materialLink'), name: 'materialLink', meta: {title: '指南管理', isTab: true}},
		{path: '/orderCenter', component: _import('orderCenter/orderCenter'), name: 'orderCenter', meta: {title: '司机订单', isTab: true}},
		{path: '/business', component: _import('business/business'), name: 'business', meta: {title: '投诉中心', isTab: true}},

		// {path: '/riderOrder', component: _import('riderOrder/riderOrder'), name: 'riderOrder', meta: {title: '司机接单', isTab: true}},
		{path: '/pattern', component: _import('riderOrder/pattern'), name: 'pattern', meta: {title: '模式配置', isTab: true}},
		{path: '/feedback', component: _import('message/feedback'), name: 'feedback', meta: {title: '反馈中心', isTab: true}},
		{path: '/riderTop', component: _import('riderTop/riderTop'), name: 'riderTop', meta: {title: '司机排行榜', isTab: true}},
		{path: '/serviceType', component: _import('serviceType/serviceType'), name: 'serviceType', meta: {title: '同城服务类型', isTab: true}},
		{path: '/riderScheduling',component: _import('riderScheduling/riderScheduling'),name: 'riderScheduling',meta: {title: '司机调度', isTab: true}},
		{ path: '/coupon', component: _import('campus/coupon'), name: 'coupon', meta: { title: '红包列表',isTab: true} },
		{ path: '/couponList', component: _import('campus/couponList'), name: 'couponList', meta: { title: '红包领取记录',isTab: true} },
		// 4.0新增
		{path: '/commentList', component: _import('message/commentList'), name: 'commentList', meta: {title: '评价列表', isTab: true}},
		{path: '/invoice', component: _import('invoice/invoice'), name: 'invoice', meta: {title: '发票管理', isTab: true}},
		{path: '/recruitList',component: _import('recruit/recruitList'),name: 'recruitList',meta: {title: '推广申请',isTab: true}},
		{path: '/IntegralGoods', component: _import('integral/IntegralGoods'), name: 'IntegralGoods', meta: {title: '充值配置', isTab: true}},
		{path: '/exchangeList', component: _import('integral/exchangeList'), name: 'exchangeList', meta: {title: '充值记录', isTab: true}},
		{path: '/top', component: _import('riderTop/top'), name: 'top', meta: {title: '推广员收益排行榜', isTab: true}},
		// 5.0 新增
		{path: '/rewardSystem', component: _import('integral/rewardSystem'), name: 'rewardSystem', meta: {title: '司机奖励制度', isTab: true}},
		{path: '/riderSchedulingRw',component: _import('riderScheduling/riderSchedulingRw'),name: 'riderSchedulingRw',meta: {title: '任务地图', isTab: true}},
		{ path: '/couponYhq', component: _import('campus/couponYhq'), name: 'couponYhq', meta: { title: '优惠券',isTab: true} },
		{ path: '/couponuser', component: _import('campus/couponuser'), name: 'couponuser', meta: { title: '优惠券领取记录',isTab: true} },
		{path: '/messageZx', component: _import('message/messageZx'), name: 'messageZx', meta: {title: '注销信息', isTab: true}},
		{path: '/dituguihua', component: _import('mission/dituguihua'), name: 'dituguihua', meta: {title: '线路规划', isTab: false}},
		{path: '/emergencyHelp', component: _import('business/emergencyHelp'), name: 'emergencyHelp', meta: {title: '紧急求助', isTab: true}},
		{path: '/prohibitedAreas', component: _import('business/prohibitedAreas'), name: 'prohibitedAreas', meta: {title: '禁止区域管理', isTab: true}}, 
		{path: '/riderSchedulingFw',component: _import('riderScheduling/riderSchedulingFw'),name: 'riderSchedulingFw',meta: {title: '禁止区域地图', isTab: false}},
		
	],
	beforeEnter (to, from, next) {
		let token = Vue.cookie.get('token')
		if (!token || !/\S/.test(token)) {
			clearLoginInfo()
			next({name: 'login'})
		}
		next()
	}
}

const router = new Router({
	mode: 'history', //  hash
	scrollBehavior: () => ({y: 0}),
	isAddDynamicMenuRoutes: false, // 是否已经添加动态(菜单)路由
	routes: globalRoutes.concat(mainRoutes)
})

router.beforeEach((to, from, next) => {
	let userId = Vue.cookie.get('userId')
	let menuList = sessionStorage.getItem("menuList");
	// 添加动态(菜单)路由
	// 1. 已经添加 or 全局路由, 直接访问
	// 2. 获取菜单列表, 添加并保存本地存储
	if(userId!='' && menuList &&( menuList=='[]' || menuList.length==0)){
		http({
			url: http.adornUrl('/sys/menu/nav'),
			method: 'get',
			params: http.adornParams()
		}).then(({data}) => {
			if (data && data.code === 0) {
				fnAddDynamicMenuRoutes(data.menuList)
				router.options.isAddDynamicMenuRoutes = true
				sessionStorage.setItem('menuList', JSON.stringify(data.menuList || '[]'))
				sessionStorage.setItem('permissions', JSON.stringify(data.permissions || '[]'))
	
				next({...to, replace: true})
			} else {
				sessionStorage.setItem('menuList', '[]')
				sessionStorage.setItem('permissions', '[]')
				next()
			}
		}).catch((e) => {
			console.log(`%c${e} 请求菜单列表和权限失败，跳转至登录页！！`, 'color:blue')
			router.push({name: 'login'})
		})
	}else if (router.options.isAddDynamicMenuRoutes || fnCurrentRouteType(to, globalRoutes) === 'global') {
		next()
	} else {
		http({
			url: http.adornUrl('/sys/menu/nav'),
			method: 'get',
			params: http.adornParams()
		}).then(({data}) => {
			if (data && data.code === 0) {
				fnAddDynamicMenuRoutes(data.menuList)
				router.options.isAddDynamicMenuRoutes = true
				sessionStorage.setItem('menuList', JSON.stringify(data.menuList || '[]'))
				sessionStorage.setItem('permissions', JSON.stringify(data.permissions || '[]'))
				next({...to, replace: true})
			} else {
				sessionStorage.setItem('menuList', '[]')
				sessionStorage.setItem('permissions', '[]')
				next()
			}
		}).catch((e) => {
			console.log(`%c${e} 请求菜单列表和权限失败，跳转至登录页！！`, 'color:blue')
			router.push({name: 'login'})
		})
	}
})

/**
 * 判断当前路由类型, global: 全局路由, main: 主入口路由
 * @param {*} route 当前路由
 */
function fnCurrentRouteType (route, globalRoutes = []) {
	var temp = []
	for (var i = 0; i < globalRoutes.length; i++) {
		if (route.path === globalRoutes[i].path) {
			return 'global'
		} else if (globalRoutes[i].children && globalRoutes[i].children.length >= 1) {
			temp = temp.concat(globalRoutes[i].children)
		}
	}
	return temp.length >= 1 ? fnCurrentRouteType(route, temp) : 'main'
}

/**
 * 添加动态(菜单)路由
 * @param {*} menuList 菜单列表
 * @param {*} routes 递归创建的动态(菜单)路由
 */
function fnAddDynamicMenuRoutes (menuList = [], routes = []) {
	var temp = []
	for (var i = 0; i < menuList.length; i++) {
		if (menuList[i].list && menuList[i].list.length >= 1) {
			temp = temp.concat(menuList[i].list)
		} else if (menuList[i].url && /\S/.test(menuList[i].url)) {
			menuList[i].url = menuList[i].url.replace(/^\//, '')
			var route = {
				path: menuList[i].url.replace('/', '-'),
				component: null,
				name: menuList[i].url.replace('/', '-'),
				meta: {
					menuId: menuList[i].menuId,
					title: menuList[i].name,
					isDynamic: true,
					isTab: true,
					iframeUrl: ''
				}
			}
			// url以http[s]://开头, 通过iframe展示
			if (isURL(menuList[i].url)) {
				route['path'] = `i-${menuList[i].menuId}`
				route['name'] = `i-${menuList[i].menuId}`
				route['meta']['iframeUrl'] = menuList[i].url
			} else {
				try {
					route['component'] = _import(`modules/${menuList[i].url}`) || null
				} catch (e) {
				}
			}
			routes.push(route)
		}
	}
	if (temp.length >= 1) {
		fnAddDynamicMenuRoutes(temp, routes)
	} else {
		mainRoutes.name = 'main-dynamic'
		mainRoutes.children = routes
		router.addRoutes([
			mainRoutes,
			{path: '*', redirect: {name: '404'}}
		])
		sessionStorage.setItem('dynamicMenuRoutes', JSON.stringify(mainRoutes.children || '[]'))
		console.log('\n')
		console.log('%c!<-------------------- 动态(菜单)路由 s -------------------->', 'color:blue')
		console.log(mainRoutes.children)
		console.log('%c!<-------------------- 动态(菜单)路由 e -------------------->', 'color:blue')
	}
}

export default router
